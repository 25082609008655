.e-commerce {
	.invoice-header {
	    @include margin-padding(0,40px 40px 15px);
	   .inv-header-right {
	      a {
             display: $__block;
	      }
	      span {
             margin-bottom: 10px;
             display: block;
	      }
	      .inv-details {
	        display: $__block;
	        .inv-date {
	            @include margin-padding(0 0 5px,0);
	            span {
	                display: $__inline;
	            }
	        }
	      }
	   }
	   .company-details {
	      .invoice-title {
	         color: $__primarycolor1;
			 font-size: $__font__size__24;
			 
	      }
	      .gst-details {
	         @include margin-padding(10px 0 0, 0);
	         span {
	            color: $__black__nuetral;
	         }
	      }
	       @include respond-below(custom767) {
                @include margin-padding(30px 0 0, 0);
	       }
	   }
	   @include respond-below(custom767) {
            display: $__flex;
            flex-direction: column-reverse;
            align-items: $__normal;
            @include margin-padding(0, 20px 20px);
            .tax-invoice {
                display: $__none;
            }
       }
       .inv-header-left {
           .tax-invoice {
			   color:#2C3038;
			   font-weight: 700;
			   font-size:$__font__size__24;
               margin-bottom: 15px;
           }
       } 
	}
	.invoice-address {
	    table {
	        @include margin-padding(0, 25px 40px);
	        justify-content: $__normal;
	        .invoice-to {
	            width: 32%;
	            @include respond-below(custom767) {
	                width: 100%;
	            }
	        }
	        @include respond-below(custom767) {
	            @include margin-padding(0, 20px);
	        }
	    }
	}
	.invoice-table {
	  table {
		color: $__grey__nuetral;
        thead {
            .ecommercetable {
                background: $__tablehead__bg;
                color: $__grey__nuetral;
            }
        }
        .table_width_2 {
		   width: 38%;
		}
		tr {
		    border-bottom: 1px solid $__invborder__color;
		   .table-description {
			   color: $__black__nuetral;
			   span {
			      display: $__block;
			      color: $__grey__nuetral;			      
			   }
		    }
		    td,th {
                @include respond-below(custom992) {
		            white-space: $__nowrap;
		        } 
		    }			
		}
		@include respond-below(custom767) {
           @include margin-padding(0, 15px 20px 0);
        } 
	  }
	}
	.invoice-table-footer {
	    .table-footer-right {
	        color: $__black__nuetral;
	    }
	     @include respond-below(custom767) {
           @include margin-padding(0, 5px 10px 0);
           .total-item {
              display: $__none;
           }
        }
	}
	.total-amountdetails {
	   span {
	       display: $__block;
	   }
	   @include respond-below(custom767) {
          @include margin-padding(0, 15px 20px);
       } 
	}
	.bank-details,.terms-condition {
	    @include respond-below(custom767) {
          @include margin-padding(0, 5px 20px);
       } 
	}	
}