.invoice-card-title {
  h6 {
    margin-bottom: 24px;
    font-weight: 600;
  }
}
.invoice-content-title {
  a {
    font-size: $__font__size__13;
    padding: 10px;
    color: #28084b;
    display: inline-block;
  }
  a:hover {
    color: $__downloadcolor;
  }
}
.blog {
  &.invoice-blog {
    border: 1px solid #efefef;
    &.active {
      border: 1px solid #7638ff;
      .invoice-star {
        background: #eaddff;
        cursor: pointer;
        i {
          color: #7539ff;
        }
      }
    }
  }
}
.invoice-star {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background: #f3f3f3;
  @extend %flex-align-center;
  cursor: pointer;
  i {
    font-size: 10px;
  }
}
.template-invoice-card {
  .invoice-card-title {
    h6 {
      border-bottom: 1px solid #efefef;
      @include margin-padding(0 0 15px, 0 0 15px);
    }
  }
  .blog {
    position: relative;
    .blog-image {
      a {
        &.img-general {
          &::before {
            content: "";
            position: absolute;
            background: rgba(87, 27, 225, 0.6);
            width: 95%;
            height: 97%;
            left: 4px;
            top: 4px;
            z-index: 9;
            transform: scale(0);
          }
        }
        &.preview-invoice {
          background-color: $__white;
          @include transition(ease all 0.5s);
          bottom: 0;
          opacity: 0;
          @include position-alignment(horizontal);
          width: 24px;
          height: 24px;
          border-radius: 50px;
          @extend %flex-align-center;
          &:hover {
            i {
              color: #7539ff;
            }
          }
          i {
            color: rgba(63, 66, 84, 1);
            font-size: 12px;
          }
        }
      }
    }
    &:hover {
      .blog-image {
        a {
          &.img-general {
            &::before {
              content: "";
              transform: scale(1);
              transition: all 0.3s ease-in-out;
            }
          }
          &.preview-invoice {
            bottom: 65px;
            opacity: 1;
            z-index: 9;
            @include transition(ease all 0.5s);
          }
        }
      }
    }
    .invoice-content-title {
      @extend %display-flex-center-between;
      padding: 10px;
      width: 100%;
      a {
        padding: 0;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}
.invoice-model.modal .modal-dialog {
  max-width: 950px;
  margin-top: 70px;
}
.invoice-receipt-model {
  &.modal {
    .modal-dialog {
      max-width: 400px;
      margin-top: 70px;
    }
  }
  .receipt-wrap {
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
}
.close-model {
  background: rgba(51, 51, 51, 0.4);
  padding: 6px 14px;
  display: block;
  border-radius: 50px;
  position: $__absolute;
  right: 0;
  top: -45px;
  color: $__white;
  cursor: pointer;
  i {
    margin-right: 6px;
  }
}
.invoice-model {
  .index-five {
    background: #252526;
    border-radius: 20px;
  }
}
.preview-boxs {
  @extend %display-flex-center-between;
  a {
    color: #7539ff;
    font-weight: 600;
  }
}
.signature-preview-page {
  .inv-content {
    border: 0;
    .inv-header-left {
      span {
        color: #95979b;
      }
    }
    .invoice-to {
      span {
        font-size: 24px;
        color: #7638ff;
        font-weight: 700;
      }
    }
  }
}
.pay-online-recipt {
  .inv-header-left {
    h3 {
      margin-bottom: 6px;
      color: #2c3038;
      font-weight: 600;
    }
    p {
      color: #333333;
      font-weight: 500;
      font-size: 14px;
      span {
        font-weight: 400;
      }
    }
  }
}
.pay-online-recipt {
  .invoice-to {
    width: 100%;
  }
  .invoice-table {
    h4 {
      margin-bottom: 20px;
    }
  }
  .inv-content {
    .invoice-address {
      border-bottom: 0;
    }
  }
}
.pay-online-list {
  li {
    @extend %display-flex;
    h6 {
      width: 10%;
    }
  }
}
.pay-method-group {
  padding: 24px;
  margin: 24px;
  background: #f6f2ff;
  .card-information {
    h6 {
      font-weight: 600;
      color: #000;
      margin-bottom: 25px;
      text-align: right;
    }
  }
  .cal-icon-info:after {
    font-family: "Feather", sans-serif;
    content: "\e927";
  }
}
.payment-btns {
  margin: 24px;
}
.receipt-pay-mail {
  max-width: 500px;
  margin: 30px 383px;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  .company-logo {
    text-align: center;
    margin-bottom: 20px;
  }
  ul {
    li {
      margin-bottom: 20px;
      font-weight: 500;
      color: #3f4254;
      span {
        font-weight: 600;
      }
    }
  }
  .click-invoice-btn {
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 0px;
    font-weight: 500;
    color: #3f4254;
  }
}
.invoice-center-pay {
  display: flex;
  align-items: center;
  height: 100vh;
}
.signature-success-modal {
  .form-header {
    i {
      color: #19a855;
      font-size: 32px;
    }
  }
}
.more-invoice-detail {
  text-align: $__center;
  margin: 0px 24px;
  padding-top: 24px;
  border-top: 1px solid #f2f2f2;
  a {
    font-size: 14px;
  }
}
