.kanban-ticket-main {
	.kanban-ticket-list {
		li {
			@include margin-padding(0 8px , null);
			&:last-child {
				margin-right: 0;
				margin-left: 0;
			}
		}
		.kanban-ticket-grid {
			-ms-flex: 20%;
			flex: auto;
			max-width: 20%;	
			@include respond-below(custom1199) {
				-ms-flex: 100%;
				flex: auto;
				max-width: 100%;	
			}
			@include margin-padding(0 0 16px, 0 16px 0px 0);		
			.kanban-ticket {
				margin-bottom: 20px;
				.kanban-head {
					h6 {
						font-size: $__font__size__14;
						font-weight: $__medium;
						color: $__dark;
						@include margin-padding(null , null)
					}
					span {
						@extend %justify-content-center;
						line-height: 0;
						width: 20px;
						height: 20px;
						font-size: $__font__size__12;
						font-weight: $__semibold;
						color: $__text-warning__light;
					}
				}
							
				a {
					i {
						font-size: $__font__size__16 !important;
						font-weight: $__semibold !important;
						color: $__note__light;
					}
				}
			}
			.card-kanban {
				background: $__white;
				border: 1px solid $__white__smoke;
				box-shadow: 0px 2px 4px rgb(190 190 190 / 20%);
				@include rounded(5px);
				@include margin-padding(null, 12px);
				.kanban-box {
					.kanban-name {
						@include margin-padding(0 0 10px, null);
						.badge {
							@include margin-padding(null, 10px);
							font-size: $__font__size__11;
							min-width: 66px;
						}
						i {
							font-size: $__font__size__16 !important;
							font-weight: $__semibold !important;
							color: $__note__light;
						}
					}						
					h6 {
						@include margin-padding(0 0 12px, null);
						font-size: $__font__size__13 !important;
						font-weight: $__semibold !important;
						color: $__dark;
						@include margin-padding(0 0 12px, null);
					}
					.ticket-due {
						@include margin-padding(0 0 12px, 0 0 12px);
						border-bottom: 1px solid $__white__smoke;
						.badge {
							@include margin-padding(null, 8px);
							font-size: $__font__size__11;
							font-weight: $__regular;
							@include rounded(3px);
						}
					}
					.kanban-ticket-img {
						li {
							margin-left: -10px;
							&:first-child{
								margin: 0;
							}
							span {
								border: 2px solid $__white;
								width: 26px;
								height: 26px;
								background: $__alice__blue;
								border-radius: 50px;
								color: #4B4B9F;
							}
							img {
								border: 2px solid $__white;
								width: 26px;
								height: 26px;
								@extend %display-flex-center;
								color: #4B4B9F;

							}
						}
					}
					.progressset p {
						font-size: $__font__size__11;
					}
				}
			}
		}
	}
}
.greentext{ 
    background-color: $__success__light; 
}
.bluetext{ 
    background-color: $__violet__dark; 
}
.orangetext{ 
    background-color: $__warningcolor
 }
 .yellowtext{ 
    background-color: $__warningcolor
 }
 .redtext{ 
    background-color: $__dangerred;
 } 
 .selecttext{
	background: $__white;;
	color: $__gray__dark !important;
 }
 .select-status {
	select{
		border: 1px solid rgba(145, 158, 171, 0.32);
        padding: 4px 10px;
        color: $__white;;
        line-height: 34px;
        @include rounded(5px);
        @extend %display-flex-center-between;
 	}
 }
 .select-status select option{
    background: $__white;
    color: $__gray;
}
.custom-select-trigger {
	position: relative;
	display: block;
	width: 130px;
	padding: 0 84px 0 22px;
	font-size: $__font__size__22;
	font-weight: $__regular;
	color: $__white;;
	line-height: 60px;
	background: $__white;
	@include rounded(54px);
	cursor: pointer;
}
.ticket-assigny {
	position: $__relative;
	.ticket-assigned {
		position: $__absolute;
		left: 10px;
		top: 40px;
		z-index: 999;
		img {
			width: 25px;
			height: 25px;
		}
	}
	.select2-container--default {
		.select2-selection--single {
			.select2-selection__rendered {
				padding-left: 40px;
			}
		} 
	} 
}
