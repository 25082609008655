.modal-dialog{
    .modal-md {
        max-width: 600px;
    }
}
.form-header {
    text-align: $__center;
    margin-bottom: 30px;
    h4 {
        color: $__graybackblue;
        font-size: $__font__size__24;
        font-weight: $__medium;
        margin-bottom: 10px;
    }
}
.modal-header-title {
    h4 {
        font-weight: $__semibold;
        font-size: $__font__size__20;
        color: $__dark__black;
    }
}
.bank-details {
    .bank-inner-details {
        label {
            font-size: 16px;
            font-weight: 600;
            color: $__graybackblue;
            margin-bottom: 10px;
        }
        .form-control {
            height: 50px;
        }
    }
}

.status-toggle {
	.checktoggle {
		cursor: $__pointer;
		display: $__block;
		font-size: 0;
		height: 24px;
		margin-bottom: 0;
		position: $__relative;
		width: 48px;
		top: 0px;
        @include rounded(12px);
        transform: translate(calc(-30% - 5px), -60%);
		&:after {
			height: 15px;
			width: 15px;
		}

	}
	.check {
		display: $__block;
		margin: 0;
		padding: 0;
		width: 0;
		height: 0;
		visibility: $__hidden;
		opacity: 0;
		pointer-events: $__none;
		position: absolute;
		&:checked {
			& + .checktoggle {
                background-color: $__primarycolor;
                border: 1px solid $__primarycolor;
				&:after {
					background-color: $__white;
					left: 100%;
					transform: translate(calc(-100% - 2px), -50%);
					height: 20px;
					width: 20px;
				}
			}
		}
	}
	.checktoggle{
        &:after {
            content: ' ';
            display: $__block;
            background-color: $__white;
            height: 20px;
            width: 20px; 
            @include transform(translate(0px, -50%));
            @include position($__absolute,null,null,null,0);
            @include rounded(50%); 
            @include position($__absolute,50%,null,null,2px);
            @include transition(all 0.2s ease);
        } 
        .checkbox-bg {
            &::after {
                background: rgba(0, 0, 0, 0.25);
            }
        }
	}
}
.close-btn {
    border: 0;
    outline: 0;
    background: transparent;
    font-size: 18px;
    i {
        font-size: 20px;
        color: #F0142F;
    }
}
.del-icon {
    margin-bottom: 23px;
    i {
        font-size: 60px;
        color: #F0142F;
        margin-bottom: 23px;
    }
}
.submit-section {
    .btn {
        padding: 7px 12px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 600;
        min-width: 150px;
        margin-top: 5px;
    }
}
.custom-modal {
    .modal-header {
        @include margin-padding(null, 24px);
    }
    .modal-body {
        @include margin-padding(null, 24px);
        .form-header {
            h3 {
                font-size: 22px;
                @include margin-padding(0 0 10px, null)
            }
        }
    }
}
.bank-details {
    .modal-header {
        border: 0;
        @extend %display-flex-center-between;
        @include margin-padding(null, 20px)
    }
    .modal-body {
        @include margin-padding(null, 0 20px 0)
    }
    .modal-footer {
        justify-content: end;
        padding: 30px;
    }
  
}
.modal-content {
    background: $__white;
    box-shadow: 0px 20px 13px rgb(0 0 0 / 3%), 0px 8px 5px rgb(0 0 0 / 8%);
    @include rounded(16px);
}
.modal-backdrop{
    &.show {
        opacity: 0.4;
    }
}
.add-tax-btns {
    .add-btn-cancel-btn {
        padding: 8px 15px;
        @include margin-padding(0 10px 0 0, 8px 15px);
        min-width: 81px;
        color: $__grayish__blue;
        background: $__white;
        border: 1px solid $__light__gray;
        box-shadow: inset 0 0 0 0 $__grayish__blue;
        border-radius: 6px;
        &:hover {
            color: $__white;
            background: $__grayish__blue;
            border-color: $__grayish__blue;
            box-shadow: inset 0 50px 0 0 $__grayish__blue;
        }
    }
    .add-tax-save-btn {
        @include margin-padding(0 0 0 0, 8px 15px);
        min-width: 69px;
    }
}
.close {
    background: transparent;
    border: 0;
    color: #1B2559;
    font-size: 28px;
    line-height: normal;
    top: 20px;
    width: auto;
    height: auto;
    right: 20px;
    span {
        background: #F3F3F9;
        border-radius: 50px;
        font-size: 20px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        transition: all 0.4s ease;
    }
}
